import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/wcenatus/Documents/GitHub/cwa-site/src/templates/MdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "http://newsroom.mastercard.com/press-releases/mastercard-announces-acquisition-of-applied-predictive-technologies/",
          "title": "Read the MasterCard release"
        }}>{`MasterCard Inc.`}</a></strong>{` announced Monday it is spending $600 million to buy Applied Predictive Technologies Inc., an Arlington, Va.-based company that provides analytics services to retailers, financial institutions, and other companies.  The deal, expected to close in the second quarter, provides MasterCard with yet another service for merchants.`}</p>
    <p>{`“Merchants, like financial institutions and governments, are one of our core customers,” a MasterCard spokesman tells `}<em parentName="p">{`Digital Transactions News`}</em>{` in an email. “Applied Predictive Technologies’ world-class talent, technology, and Test & Learn platform, matched with MasterCard’s analytics, will give our customers the advantage of enhanced and operationalized decision making.”`}</p>
    <p>{`Test & Learn is a cloud-based software tool that enables clients to test various scenarios. Among them is a basket analyzer that can help retailers determine which products to promote and which to eliminate and options for structuring promotions. Another scenario might be quantifying the impact of online advertising on in-store sales.`}</p>
    <p>{`MasterCard says Applied Predictive Technologies now will have access to MasterCard’s analytics tools, consulting, and marketing services. Applied Predictive Technologies also has office in San Francisco, London, Taipei, Taiwan, Tokyo, Sydney, and Bentonville, Ark.`}</p>
    <p>{`From `}<a parentName="p" {...{
        "href": "http://digitaltransactions.net/news/story/MasterCard-Makes-an-Analytics-Deep-Dive-With-a-_600-Million-Acquisition"
      }}>{`Digital Transactions`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      